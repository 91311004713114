import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faPrint } from '@fortawesome/free-solid-svg-icons';
import { getAllProductApi, getAllCategoryApi, getAllSubcategoriesApi, deleteProductByIdApi, editProductByIdApi, baseURL } from '../../../Apis/Api'; // Adjust the import path based on your project structure
import ProductModal from './view/ViewProductModel';
import Barcode from 'react-barcode';
import ReactDOM from 'react-dom';
import EditProductModal from './view/EditProductModel';
import toast from 'react-hot-toast';

const ViewProduct = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state


  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const productResponse = await getAllProductApi();
        const categoryResponse = await getAllCategoryApi();
        const subCategoryResponse = await getAllSubcategoriesApi(); // Fetch all subcategories

        if (productResponse.status === 200) {
          setProducts(productResponse.data.products || []);
        } else {
          setError('Failed to fetch products');
        }

        if (categoryResponse.status === 200) {
          setCategories(categoryResponse.data.categories || []);
        } else {
          setError('Failed to fetch categories');
        }

        if (subCategoryResponse.status === 200) {
          setSubCategories(subCategoryResponse.data.subCategories || []); // Set the fetched subcategories
        } else {
          setError('Failed to fetch subcategories');
        }
      } catch (error) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const handleSaveProduct = async (updatedProduct) => {
    console.log('updatedProduct: ', updatedProduct);
    try {
      const response = await editProductByIdApi(selectedProduct._id, updatedProduct);
      toast.success('Product updated successfully!');
      console.log('Product updated successfully:', response.data);
    } catch (error) {
      toast.error('Failed to update the product. Please try again.');
      console.error('Error:', error.response?.data || error.message);
    }
  };

  const handleView = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setEditModalOpen(true);
  };

  const handleDelete = async (productId) => {
    const confirmed = window.confirm("Are you sure you want to delete this product?");
    if (confirmed) {
      try {
        const response = await deleteProductByIdApi(productId);
        if (response.status === 200) {
          // Remove the deleted product from the state
          setProducts((prevProducts) => prevProducts.filter(product => product._id !== productId));
        } else {
          setError('Failed to delete the product. Please try again.');
        }
      } catch (err) {
        setError('An error occurred while deleting the product. Please try again later.');
      }
    }
  };

  const handlePrintBarcode = (sku, wholesaleCode, discountedPrice) => {
    const printWindow = window.open('', '', 'width=400,height=200');

    const styles = `
      @media print {
        body {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center; /* Center horizontally */
          align-items: center; /* Center vertically */
          height: 100vh; /* Full height for vertical centering */
        }
        .barcode-label {
          width: 1.57in; /* Exact label width */
          height: 1.118in; /* Exact label height */
          display: flex;
          flex-direction: column; /* Stack items vertically */
          justify-content: center; /* Center content inside the label */
          align-items: center; /* Center content inside the label */
          position: relative; /* Allow absolute positioning for the top-left code */
        }
        .wholesale-code {
          position: absolute;
          top: 5px;
          left: 5px;
          font-size: 8px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          padding: 0 2px;
          text-overflow: ellipsis; /* Handle long codes */
        }
        .barcode-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        .barcode-text {
          font-size: 9px;
          text-align: center;
          margin-top: 5px;
        }
        svg {
          max-width: 100%; /* Ensure barcode fits within the label */
          max-height: 100%;
        }
      }
    `;

    printWindow.document.head.innerHTML = `
      <style>${styles}</style>
    `;

    printWindow.document.body.innerHTML = `
      <div class="barcode-label">
        <div class="wholesale-code">${wholesaleCode}</div>
        <div class="barcode-container">
          <div id="barcode"></div>
          <div class="barcode-text">Npr. ${discountedPrice}</div>
        </div>
      </div>
    `;

    ReactDOM.render(
      <Barcode
        value={sku}
        displayValue={true}
        width={1.2}
        height={50}
        fontSize={10} /* Adjust for readable text */
        margin={2} /* Minimal margins */
        textMargin={2}
      />,
      printWindow.document.getElementById('barcode'),
      () => {
        printWindow.print();
        printWindow.close();
      }
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Create a map for categories
  const categoryMap = categories.reduce((map, category) => {
    map[category._id] = category.name;
    return map;
  }, {});

  // Create a map for subcategories
  const subCategoryMap = subCategories.reduce((map, subCategory) => {
    map[subCategory._id] = subCategory.name; // Store subcategory name
    return map;
  }, {});

  // Filter products based on the search term
  // Filter products based on the search term
  const filteredProducts = products.filter(product => 
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="total-product p-2 gap-4 rounded-md w-fit flex justify-between items-center ">
      <div className="flex gap-6 mt-5">
          <div className="total-product p-2 gap-4 bg-gray-100 rounded-md w-fit flex justify-between items-center">
            <div>
              <h2 className="flex gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
                Total Listed Product: {products.filter((product) => product.showProductinSite).length}
              </h2>
            </div>
          </div>

          <div className="total-product p-2 gap-4 bg-gray-100 rounded-md w-fit flex justify-between items-center">
            <div>
              <h2 className="flex gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
                Total Listed Product Cost: Npr.{' '}
                {products
                  .filter((product) => product.showProductinSite)
                  .reduce((acc, product) => acc + (product.totalQuantity || 0) * (product.price || 0), 0)
                  .toLocaleString()}
              </h2>
            </div>
          </div>
        </div>



      </div>
      {/* Search Input */}
      <div className="relative mb-4 mt-2">
        <input
          type="search"
          id="default-search"
          className="block w-[50%] p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Search Products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="text-[#AB3430] font-bold text-3xl mt-5">All Listed Products</div>
      {/* Table to display products */}
      <table className="table-auto w-full mt-5">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">Product Image</th>
            <th scope="col" className="px-6 py-3">Product Name</th>
            <th scope="col" className="px-6 py-3">Actual Cost Price</th>
            <th scope="col" className="px-6 py-3">Selling Price</th>
            <th scope="col" className="px-6 py-3">Category</th>
            <th scope="col" className="px-6 py-3">SubCategory</th>
            <th scope='col' className='px-6 py-3'>Barcode</th>
            <th scope="col" className="px-6 py-3">Sizes and Quantities</th>
            <th scope="col" className="px-6 py-3">Total Quantity</th>
            <th scope="col" className="px-6 py-3">Total Cost</th>
            <th scope="col" className="px-6 py-3">Product entry time</th>
            <th scope="col" className="px-6 py-3">Show Product</th>
            <th scope="col" className="px-6 py-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts
            .filter(product => product.showProductinSite) // Only show products where showProductinSite is true
            .map(product => (
              <tr key={product._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="px-6 py-4 flex items-center gap-2">
                  <img src={`${baseURL}/${product.images[0]}`} alt="product" className="h-[100px] w-[70px]" />
                </td>
                <td className="px-6 py-4">{product.name}</td>
                <td className="px-6 py-4 text-green-600">Npr. {product.price}</td>
                <td className="px-6 py-4 text-red-600 ">Npr. {product.discountedPrice}</td>
                <td className="px-6 py-4">{categoryMap[product.category]}</td>
                <td className="px-6 py-4">{subCategoryMap[product.subcategory] || 'N/A'}</td> {/* Update here */}
                <td className="px-6 py-4"> <Barcode value={product.sku} /> </td>
                <td className="px-6 py-4">
                  {product.sizes.map(size => (
                    <div key={size._id}>
                      {size.size}: {size.colors.map(color => (
                        <span key={color._id}>{color.color} ({color.quantity})</span>
                      )).reduce((prev, curr) => [prev, ', ', curr])}
                    </div>
                  ))}
                </td>
                <td className="px-6 py-4">{product.totalQuantity}</td>
                <td className="px-6 py-4">
                  Npr. {product.totalQuantity * product.price}
                </td>
                <td className="px-6 py-4">
                    {new Date(product.createdAt).toLocaleDateString()}
                  </td>
                <td className="px-6 py-4">
                  {product.showProductinSite ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  )}
                </td>
                <td className="px-6 py-4 flex gap-2">
                  <button onClick={() => handleView(product)}>
                    <FontAwesomeIcon icon={faEye} className="text-blue-500 hover:text-blue-700 cursor-pointer" />
                  </button>
                  <button onClick={() => handleEdit(product)}>
                    <FontAwesomeIcon icon={faEdit} className="text-yellow-500 hover:text-yellow-700 cursor-pointer" />
                  </button>

                  <button onClick={() => handleDelete(product._id)}>
                    <FontAwesomeIcon icon={faTrash} className="text-red-500 hover:text-red-700 cursor-pointer" />
                  </button>
                  <button onClick={() => handlePrintBarcode(product.sku, product.wholesaleCode, product.discountedPrice)}>
                    <FontAwesomeIcon icon={faPrint} className="text-green-500 hover:text-green-700 cursor-pointer" />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Orders Per Page Dropdown */}
     

      {/* Modals */}
      {isModalOpen && (
        <ProductModal
          product={selectedProduct}
          categories={categories} // Pass categories here
          subCategories={subCategories} // Pass subCategories here
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
      {editModalOpen && (
        <EditProductModal
          product={selectedProduct}
          isEditing={true}
          isOpen={editModalOpen} // Use the correct state here
          onClose={handleCloseEditModal} // Call the proper close handler
          categories={categories}
          subCategories={subCategories}
          onSave={handleSaveProduct}
        // Optionally add an onSave callback if needed
        />
      )}
    </div>
  );
};

export default ViewProduct;
