import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getDailySalesApi } from '../../Apis/Api'; 
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DailySale = () => {
  const [dailySalesData, setDailySalesData] = useState(null);

  useEffect(() => {
    const fetchDailySales = async () => {
      try {
        const response = await getDailySalesApi(); 
        setDailySalesData(response.data.data);
      } catch (error) {
        console.error('Error fetching daily sales:', error);
      }
    };

    fetchDailySales();
  }, []);

  if (!dailySalesData) {
    return <p>Loading daily sales...</p>; // Show a loader until data is fetched
  }

  const labels = ['Online Sales', 'Offline Sales', 'Total Sales'];
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Sales Count',
        data: [
          dailySalesData.onlineSales,
          dailySalesData.offlineSales,
          dailySalesData.totalDailySales,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 205, 86, 0.2)',
        ],
        borderColor: [
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Daily Sales Bar Chart',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="w-full max-w-lg mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-4 text-center">Daily Sales</h2>
      <Bar data={data} options={options} />
    </div>
  );
};

export default DailySale;
